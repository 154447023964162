@import url('https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,500,600,700|Roboto:300,400,700|Sen:400,700&display=swap|Open+Sans:300,400,600,700');

$bg-light: rgba(237, 239, 245, 0.6);
$secondary-color: #77d3f5;
$main-purple: #4d6fff;
$main-blue: #00bcdf;
$main-pink: #ed5e94;
$accent-color: #67D4D6;
$dark-blue: #3b5998;

$font-stack: 'Poppins', 'Roboto', sans-serif;
$secondary-font-stack: 'Lato', sans-serif;
$font-primary-color: #282f48;

%slanted-container {
  transform: skewY(3deg);
  background-color: white;
}

%slanted-content {
  transform: skewY(-3deg);
}

%equal-heights {
  display: flex;
  flex-wrap: wrap;
}

%text-style {
  text-align: left;
  line-height: 2em;
  font-size: 16px;
  font-family: $font-stack;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
}

img {
  display: block;
}

ul {
  margin: auto;
}

li {
  list-style: none;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 20px 40px;
  padding-top: 30px;
  font-family: $font-stack;
  background-color: #282f40;
  z-index: 99;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
}

.active-link {
  font-weight: bold;
}

.link {
  color: #08BCDF
}

.avatar {
  margin: 0 auto 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.job-label-container {
  @extend %equal-heights;
  justify-content: flex-start;
  
  .job-label {
    font-size: 0.9em;
    margin: 10px 5px;
    padding: 5px 10px;
    border-radius: 10px;
    border: 0;
    color: black;
    width: fit-content
  }
}

.btn {
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 0.9em;
  margin: 20px auto;
  cursor: pointer;
  text-align: center;
}

.btn-circle {
  width: 35px; 
  height: 35px; 
  border-radius: 50%; 
}

.btn-circle.btn {
  padding: unset;
}

.purple-btn {
  background-color: $main-purple;
  color: white;
  box-shadow: 5px 3px 15px rgba(77, 111, 255,0.2);
}


.pink-btn {
  background-color: $main-pink;
  color: white;
  box-shadow: 5px 3px 15px rgba(237, 94, 148,0.2);
}


.blue-btn {
  background-color: $main-blue;
  color: white;
  box-shadow: 5px 3px 15px rgba(0, 188, 223,0.2);

  
}

.dark-blue-btn {
  background-color: $dark-blue;
  color: white;
  box-shadow: 5px 3px 15px rgba(59, 89, 152,0.2);

}

.pink-btn:hover, .blue-btn:hover, .purple-btn:hover, .dark-blue-btn:hover {
  color: #fff;
  transition: liftUp;
  
}

.blue-btn-reversed {
  background-color: white;
  color: $main-blue;
}

.pink-btn-reversed {
  background-color: white;
  color: $main-pink;
}


.bg-illustration {
  width: 100%;
  // top: -220px;
  // left: -50px;
  position: absolute;
  z-index: -100;
}

.header {
    padding: 50px 20px 0 20px;
    @extend %equal-heights;
    justify-content: space-between;

    .container {
      padding: 0;
    }
    
    h1 {
      font-weight: bold;
      font-family: "Poppins";
      font-size: 1.8em
    }
}

.container.content-container {
  padding: 30px;
}

.spaced-between {
  @extend %equal-heights;
  justify-content: space-between;
}

.flex-centered {
  @extend %equal-heights;
  justify-content: center;
}

.grid-container {
  display: grid;
  grid-gap: 20px;    
}

.checkbox-label {
  color: #000;
  font-size: 0.9em;
}
.tab-container {
  margin: auto;
  background-color:rgba(0,0,0,0);

  .ant-tabs-nav-wrap {
      background-color: #fff;
      padding: 0px 30px;
  }

  .ant-tabs-tabpane {
      padding: 20px 20px 40px 20px;
      min-height: 50vh;
  }

}


.tab-light-bg {
  .ant-tabs-tab-btn {
      font-size: 12px !important;
  }


  .tab-container {
      background-color: $bg-light !important;
      min-height: 100vh;

      .ant-tabs-nav-wrap {
          background-color: #fff;
      }
  }

  .tab-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:hover {
      color: $main-blue !important;
  }

  .ant-tabs-ink-bar {
      background: $main-blue !important;
    }
}

@media (min-width: 768px ) {
  .grid-container {
      grid-template-columns: 1fr 1fr;

  }

  
  
}


@media (min-width: 1200px ) {
    .grid-container {
        grid-template-columns: 1fr 1fr 1fr;
    }

  
}

@media (min-width: 500px) {
  .header {
    padding: 30px 20px 0 20px;
  }
  .container.content-container {
    padding: 30px 80px;
  }
}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

@keyframes fadeout {
  from {
      opacity:1;
  }
  to {
      opacity:0;
  }
}

@keyframes slidedown {
  from {
      transform: translate3d(-100%,0,0);
  }
  to {
    transform: translate3d(0,0,0);
  }
}

@keyframes liftUp {
  from {
      transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(5,0,0);
  }
}

